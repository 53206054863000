import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import React, { useState, useEffect } from 'react';
import axios from 'axios';

const TenureSelect = ({ data, setData }) => {

    const [loanTypes, setLoanTypes] = useState([]);
    const [selectedLoan, setSelectedLoan] = useState(0);

    const handleChange = (event) => {
      setData({...data, rate: event.target.value});
      setSelectedLoan(event.target.value);
    };


    useEffect(() => {
    
        axios.get('https://gpnserver.pickmesms.com/items/loantypes?fields=loan_name,duration,interest_rate')
        .then(response => {
        
            setLoanTypes(response.data.data);
            setSelectedLoan(response.data.data[0].interest_rate/100);
        })
        .catch(error => {
            
            console.error('There was an error fetching the data:', error);
        });
    }, []);
  
    return (
        <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Loan Type</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={selectedLoan}
          label="Tenure"
          defaultValue={selectedLoan}
          onChange={handleChange}
        >


          {loanTypes.map((loan, index) => (
          <MenuItem key={index} value={loan.interest_rate/100}>
            {loan.loan_name} ({loan.duration} Days) at {loan.interest_rate}%
          </MenuItem>
        ))}
        </Select>
      </FormControl>
    )
  };
  
  export default TenureSelect;