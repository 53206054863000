import './App.css';
import Navbar from './components/Navbar';
import React, { useState } from "react";
import { Grid } from "@mui/material";
import { Container } from "@mui/system";
//import Result from "./Components/Result";
import SliderSelect from "./components/SliderSelect";
import TenureSelect from "./components/TenureSelect";

function App() {

  const [data, setData] = useState({
    minimumLoan: 500,
    loan:500,
    rate: 0.3
  })


  return (
    <div className="App">
    {/* <Navbar /> */}
    <Container maxWidth="xl" sx={{marginTop:4}}>
      <Grid container spacing={5} alignItems="center">
        <Grid item xs={12} md={6}>
        <TenureSelect data={data} setData={setData}/>
          <SliderSelect data={data} setData={setData} />
         
        </Grid>
        <Grid item xs={12} md={6}>
          {/* <Result data={data}/> */}
        </Grid>
      </Grid>
    </Container>
  </div>
  );
}

export default App;
