import React from "react";
// import { Container, Typography } from "@mui/material";
import SliderComponent from "./SliderComponent";

const SliderSelect = ({ data, setData }) => {
  
  return (
    <div>
      <SliderComponent
        onChange={(e, value) =>
          setData({
            ...data,
            loan: value.toFixed(0),
          })
        }
        defaultValue={data.loan}
        min={0}
        max={10000}
        steps={500}
        unit="K"
        amount={data.loan}
        label="Loan Amount"
        value={data.loan}
      />


      <SliderComponent
   
        defaultValue={Number(data.loan * data.rate) + Number(data.loan)}
        min={500}
        max={3000}
        steps={100}
        unit="K"
        amount={data.loan * data.rate}
        label="Interest"
        value={data.loan * data.rate}
      />

     
<SliderComponent
 
        defaultValue={(Number(data.rate) * Number(data.loan)) + Number(data.loan)}
        min={500}
        max={15000}
        steps={100}
        unit="K"
        amount={(Number(data.rate) * Number(data.loan)) + Number(data.loan)}
        label="PAY BACK"
        value={(Number(data.rate) * Number(data.loan)) + Number(data.loan)}
      />


   
    </div>
  );
};

export default SliderSelect;